import './../css/components/footer.css'

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer">
            &#169; {currentYear} Awesomebread Studios
        </div>
    );
};