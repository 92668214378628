import logo from './../images/logo.png'
import './../css/components/header.css'
import {useNavigate} from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();
    const handleRoot = (e: any) => {
        e.preventDefault();
        navigate("/");
    }

    return (
        <div className="header">
            <div className="headerItem">
                <a href="/" onClick={handleRoot}>
                    <img src={logo} alt="Awesomebread Studios Logo" className="logo" />
                </a>
            </div>
            <div className="headerItem nav">
                <a href="/" onClick={handleRoot} className="navItem">Home</a>
                <a href="https://blog.awesomebreadstudios.com" className="navItem">Blog</a>
                <a href="mailto:awesomebreadstudios@gmail.com" className="navItem">Contact</a>
            </div>
        </div>
    );
};