import './../css/components/home.css'

export default function Home() {
    return (<div className="home">
            <div>
                <h2>
                    Welcome to official website of Awesomebread Studios!
                </h2>
                Awesomebread Studios is the repository of Eric Day's creative pursuits.  Creating video games, literature, and music Awesomebread Studios strives to
                be a creative force without cutting corners or morals.
            </div>
        </div>
    );
};