import {Navigate, Route, Routes} from 'react-router-dom'
import Layout from "./layout";
import NotFound from "../components/notFound";
import Home from "../components/home";

export default function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/notFound" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/notFound" />} />
            </Route>
        </Routes>
    );
}