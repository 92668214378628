import {Outlet} from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";

export default function Layout() {
    return (
        <div className="studio">
            <Header />
            <div className="content">
                <div className="sideBar"/>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}